import React, { useContext, useEffect, useRef, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Typography,
} from "@mui/material";
import Logo from "../../images/site-schema-black.png";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../includes/AppContext";
import {
  Logout,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";

import ReactGa4 from "react-ga4";

const Header = () => {
  const global = useContext(AppContext);
  const goto = useNavigate();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const logOut = () => {
    // TODO: Route to a logout page that doesn't try to pull api data again */
    document.title = "Logged Out | Site Schema";
    ReactGa4.send({
      hitType: "pageview",
    });
    global.signOut();
    setTimeout(() => {
      localStorage.removeItem("monitor_alarms");
      localStorage.removeItem("monitor_alarms_cache");
      localStorage.removeItem("monitor_nextToken");
      localStorage.removeItem("monitor_uptime");
      localStorage.removeItem("monitor_uptime_cache");
      localStorage.removeItem("monitor_instances");
      localStorage.removeItem("monitor_instances_cache");
      goto("/");
    }, 1000);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <AppBar position="static" style={{ background: "white" }} elevation={0}>
      <Box className="header_bar">
        <Button
          sx={{
            backgroundImage: `url(${Logo})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "8rem",
            height: "2rem",
            margin: "0.5rem 1rem ",
          }}
          onClick={(e) => goto("/")}
        />
        {/* {console.log(global.user)} */}
        <Button
          ref={anchorRef}
          size="small"
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{
            whiteSpace: "nowrap",
            minWidth: "10rem",
            fontSize: "0.75rem",
          }}
          endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          variant="text"
        >
          {global.user.attributes.name}
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          style={{ zIndex: 20000 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <Paper
                style={{
                  minWidth: "12rem",
                  backgroundColor: "white",
                  borderRadius: 0,
                }}
              >
                {/* <Typography variant="body2" style={{ padding: "1rem" }}>
                  <b>Company:</b> {global.user.attributes["custom:company"]}
                </Typography> */}
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="basic-menu"
                    onKeyDown={handleListKeyDown}
                    aria-labelledby="composition-button"
                  >
                    <MenuItem
                      onClick={(e) => logOut()}
                      style={{
                        minWidth: "12rem",
                        display: "flex",
                        justifyContent: "space-between",
                        textAlign: "right",
                      }}
                    >
                      <Typography variant="body2">Log Out</Typography>
                      <Logout fontSize="small" />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </AppBar>
  );
};

export default Header;
