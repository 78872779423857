import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import {
  IconButton,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { CopyAll, MoreHoriz } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";

import { AppContext } from "../includes/AppContext";
import { convertBundle, getState, handleCopy } from "../includes/utilities";
import WordPressLogo from "../images/wordpress.png";
import UbuntuLogo from "../images/ubuntu.png";
// import Alarms from "./Alarms";
// import UptimeRobot from "./UptimeRobot";

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
      { outline: "none" },
  },
});

const Table = ({ focused }) => {
  const global = useContext(AppContext);
  const classes = useStyles();
  const goto = useNavigate();

  const setView = (name) => {
    goto("/view/" + name);
  };

  const [rows, setRows] = useState(null);

  const copyIpAddress = (val) => {
    global?.setMessage(
      "Successfully copied the IP Address " + val + " to your clipboard"
    );
    handleCopy(val);
  };

  useEffect(() => {
    const rows = [];
    if (global?.instances && global?.loading === false) {
      global?.instances.map((val, key) => {
        rows.push({
          id: val.name,
          num: key,
          label: val.company,
          status: val.state.name,
          plan: convertBundle(val.bundleId),
          blueprint: val.blueprintName,
          ipAddress: val.publicIpAddress,
          region: val.location.regionName,
          backupTime:
            val.addOns && val.addOns.length > 0
              ? /*val.addOns[0].status + "@" +*/ "@" +
                val.addOns[0].snapshotTimeOfDay
              : "",
          action: "",
          name: val.name,
          hardware:
            val.hardware.ramSizeInGb +
            " GB Ram, " +
            val.hardware.cpuCount +
            " vCPU, " +
            val.hardware.disks[0].sizeInGb +
            " GB SSD",
        });
        return true;
      });
      // console.log(rows);
    }
    setRows(rows);
  }, [global.instances, global.loading]);

  const columns = [
    // { field: "id", headerName: "ID", hide: true },
    {
      field: "label",
      flex: 3,
      headerName: "VPS Instance",
      headerClassName: "table__header",
      minWidth: 144,
      renderCell: (params) => {
        return (
          <Box
            className="table_row___selector"
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
            onClick={(e) => setView(params?.row.name)}
          >
            <img
              style={{ height: "1rem" }}
              src={
                params?.row.blueprint === "Ubuntu" ? UbuntuLogo : WordPressLogo
              }
              alt="VPS Instance Blueprint"
            />
            {params?.row.label}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table__header",
      minWidth: 120,
      maxWidth: 120,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {getState(params?.row.status, "small")}
          </React.Fragment>
        );
      },
    },
    {
      field: "plan",
      headerName: "Plan",
      headerClassName: "table__header",
      minWidth: 80,
      maxWidth: 80,
      flex: 1,
      hide: global.width < 720 ? true : false,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <Tooltip title={params.row.hardware || ""} arrow placement="left">
              <Box style={{ cursor: "help" }}>{params.row.plan || ""}</Box>
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      headerClassName: "table__header",
      hide: global.width < 550 ? true : false,
      minWidth: 152,
      flex: 1,
      textAlign: "right",
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.ipAddress ? (
              <Tooltip title="Copy IP Address" placement="left" arrow>
                <Box
                  onClick={(e) => {
                    copyIpAddress(params.row.ipAddress);
                    return true;
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <IconButton className="button_nav" size="small">
                    <CopyAll style={{ height: 16, width: 16 }} />
                  </IconButton>
                  {params.row.ipAddress}
                </Box>
              </Tooltip>
            ) : (
              <i className="false">&mdash;</i>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: "region",
      headerName: "Region",
      headerClassName: "table__header",
      minWidth: 120,
      maxWidth: 120,
      hide: global.width < 800 ? true : false,
    },
    {
      field: "backupTime",
      headerName: "Daily Snapshot",
      headerClassName: "table__header",
      minWidth: 120,
      maxWidth: 120,
      hide: global.width < 990 ? true : false,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "table__header",
      sortable: false,
      type: "boolean",
      renderCell: (params) => {
        return (
          <IconButton onClick={(e) => goto("/view/" + params.row.name)}>
            <MoreHoriz />
          </IconButton>
        );
      },
      //   description: "This column has a value getter and is not sortable.",
    },
  ];

  return (
    <Box
      className={
        "main_container " + (focused ? "drawer_open" : "drawer_closed")
      }
    >
      {/* <Alarms /> */}

      <Box
        className="grid_container"
        style={{
          width: "100%",
          height: "max-content",
        }}
      >
        {rows ? (
          <DataGrid
            className={classes.root}
            density={global.width < 600 ? "compact" : "standard"}
            rows={rows}
            columns={columns}
            pageSize={global?.height < 720 ? 15 : 30}
            rowsPerPageOptions={global?.height < 720 ? [15] : [30]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            disableSelectionOnClick
            disableColumnMenu
            hideFooterSelectedRowCount
            sortModel={[
              {
                field: "label",
                sort: "asc",
              },
            ]}
            sx={{ borderRadius: 0 }}
          />
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "1rem",
              width: "100%",
              height: "100vh",
              alignItems: "center",
              color: "#888",
            }}
          >
            <CircularProgress style={{}} />
            <Typography variant="caption">Loading</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Table;
