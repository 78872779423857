import React from "react";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const InstanceSummary = ({ instance }) => {
  return (
    <Box className="grid_main___block">
      <Typography className="grid_main___head" variant="body1">
        VPS Instance
      </Typography>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          Memory
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {instance?.hardware?.ramSizeInGb} GB Ram
        </Typography>
      </Box>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          Processing
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {instance?.hardware?.cpuCount} vCPU
        </Typography>
      </Box>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          Storage
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {instance?.hardware?.disks[0].sizeInGb} GB SSD
        </Typography>
      </Box>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          Transfer
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {instance?.networking?.monthlyTransfer?.gbPerMonthAllocated &&
            instance?.networking?.monthlyTransfer?.gbPerMonthAllocated /
              1024}{" "}
          TB
        </Typography>
      </Box>
    </Box>
  );
};

export default InstanceSummary;
