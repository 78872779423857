/* @ts-check */
/* eslint-disable */
import { Check } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../includes/AppContext";
import cacheCheck from "../../includes/cacheCheck";
import { defineOperationType } from "../../includes/utilities";

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
      { outline: "none" },
  },
});

const InstanceOperations = ({ instance }) => {
  const global = useContext(AppContext);
  const classes = useStyles();

  const [operations, setOperations] = useState(null);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      headerClassName: "table__header",
      type: "boolean",
      renderCell: (params) => {
        return moment(params.row.createdAt).fromNow(true);
      },
    },

    {
      field: "operationType",
      headerName: "Event Type",
      flex: 1,
      headerClassName: "table__header",
      sortable: false,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {defineOperationType(params.row.operationType)}
          </React.Fragment>
        );
      },
    },
    {
      field: "operationDetails",
      headerName: "Additional Details",
      headerClassName: "table__header",
      sortable: false,
      flex: 3,
      hide: global.width < 800 ? true : false,
    },
    {
      field: "duration",
      headerName: "Duration",
      headerClassName: "table__header",
      sortable: false,
      type: "boolean",
      hide: global.width < 800 ? true : false,
      renderCell: (params) => {
        return (
          <React.Fragment>
            {moment(params.row.statusChangedAt).diff(
              params.row.createdAt,
              "seconds"
            ) + " sec"}
          </React.Fragment>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table__header",
      sortable: false,
      type: "boolean",
      renderCell: (params) => {
        return (
          <React.Fragment>
            {params.row.status === "Succeeded" ? (
              <Avatar
                style={{
                  height: "1rem",
                  width: "1rem",
                  backgroundColor: green[600],
                }}
              >
                <Check style={{ color: "white", fontSize: "0.875rem" }} />
              </Avatar>
            ) : (
              <i className="false">&mdash</i>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    const cache = cacheCheck({
      cacheName: global.config.lsOps,
      cacheLength: global.config.length,
      cacheUnit: global.config.unit,
    });

    if (cache) {
      const response = localStorage.getItem(global.config.lsOps);
      const dataObj = JSON.parse(response["operations"]);
      setOperations(dataObj);
      setLoading(false);
    } else {
      fetch(
        global.config.url +
          "/instance/operations/?resourceName=" +
          instance?.name
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          const dataObj = JSON.parse(data.message);
          setOperations(dataObj.operations);
          setLoading(false);

          console.log(dataObj.operations);

          localStorage.setItem(
            global.config.lsOps,
            JSON.stringify(dataObj.operations)
          );
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          global?.setError(error);
        })
        .finally();
    }
  }, [instance.name]);

  return (
    <Box className="grid_main___block">
      <Typography className="grid_main___head" variant="body1">
        Activity Feed
      </Typography>
      {operations && operations.length > 0 && loading === false && (
        <React.Fragment>
          <Box
            className="grid_container"
            style={{
              width: "100%",
            }}
          >
            <DataGrid
              autoHeight
              className={classes.root}
              density={global.width < 600 ? "compact" : "standard"}
              rows={operations}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              disableSelectionOnClick
              disableColumnMenu
              hideFooterSelectedRowCount
              sx={{ borderRadius: 0 }}
            />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default React.memo(InstanceOperations);
