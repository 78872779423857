/* @ts-check */
/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

/* Material UI Components */
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";

/* Site Schema Components */
import { AppContext } from "../includes/AppContext";
import Dashboard from "./Table";
import Header from "./elements/Header";

import InstanceHead from "./elements/InstanceHead";
import InstanceSummary from "./elements/InstanceSummary";
import InstanceDetails from "./elements/InstanceDetails";
import InstanceAddOns from "./elements/InstanceAddOns";
import Breadcrumb from "./elements/Breadcrumb";
import InstanceIpAddresses from "./elements/InstanceIpAddresses";
import InstanceOperations from "./elements/InstanceOperations";
// import InstanceMetrics from "./elements/InstanceMetrics";
// import ReactGa4 from "react-ga4";

const Main = () => {
  const global = useContext(AppContext);
  const param = useParams();
  const goto = useNavigate();

  const [value, setValue] = useState("overview");
  const [focused, setFocused] = useState(null);
  const [instance, setInstance] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* Set focused instance from URL params */
  useEffect(() => {
    const uid = param.instanceid;
    if (uid?.length > 0) {
      setFocused(uid);
    } else {
      setFocused(null);
      localStorage.removeItem(global?.config?.lsOps);
    }
  }, [param]);

  /* Tailor page to focused instance */
  useEffect(() => {
    if (
      "instances" in global &&
      global.instances &&
      global.instances.length > 0 &&
      focused
    ) {
      const temp = global.instances.find((element) => {
        document.title =
          element.company + " | " + global.user.attributes["custom:company"];

        // ReactGa4.send({
        //   hitType: "pageview",
        // });

        return element.name === focused;
      });
      setInstance(temp);
    }
  }, [focused, global]);

  /* Set status of instance as stop, start, reboot */
  const setStatus = (status) => {
    const opts = ["reboot", "stop", "start"];

    if (opts.includes(status)) {
      fetch(
        "https://7syz1k6odi.execute-api.us-east-1.amazonaws.com/default/lightsail/instance/status/" +
          status +
          "?resourceName=" +
          param.instanceid
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          const dataObj = JSON.parse(data.message);
          global?.setMessage(
            dataObj.operations[0].resourceName +
              " - " +
              status.toUpperCase() +
              " initiated"
          );
          localStorage.removeItem("monitor_instances");
          localStorage.removeItem("monitor_instances_cache");
          localStorage.removeItem("monitor_alarms");
          localStorage.removeItem("monitor_alarms_cache");
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          global?.setError(error);
        })
        .finally();
    }
  };

  /* Closed focused view */
  const closeView = () => {
    document.title =
      "Instances Dashboard | " + global.user.attributes["custom:company"];
    setFocused(null);
    goto("/");
  };

  return (
    <Box
      style={{
        height: global?.height + "px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "stretch",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Header />

      <Breadcrumb focused={focused} instance={instance} closeView={closeView} />
      <Container className="root_container" maxWidth={"xl"}>
        <Dashboard focused={focused} />

        {instance && (
          <Box
            className={
              "main_nav " + (focused !== null ? "drawer_open" : "drawer_closed")
            }
          >
            <Box className="main_nav___container">
              <InstanceHead
                instance={instance}
                setStatus={setStatus}
                value={value}
                handleChange={handleChange}
              />

              {value === "overview" && (
                <React.Fragment>
                  <Box className="main_nav___main">
                    <InstanceDetails instance={instance} />
                    <InstanceSummary instance={instance} />
                    <InstanceIpAddresses instance={instance} />
                  </Box>
                  <Box
                    className="main_nav___main"
                    style={{ marginTop: "1rem" }}
                  >
                    <InstanceAddOns instance={instance} />
                  </Box>
                </React.Fragment>
              )}

              {instance?.analyticsUrl && value === "analytics" && (
                <Box className="main_nav___main">
                  <iframe
                    title="site_analytics"
                    width="600"
                    height="1459"
                    src={instance?.analyticsUrl}
                    style={{
                      width: "100%",
                      maxWidth: "800px",
                      border: 0,
                      margin: "0 auto",
                    }}
                  ></iframe>
                </Box>
              )}
              {instance?.gscUrl && value === "discoverability" && (
                <Box className="main_nav___main">
                  <iframe
                    title="site_discovery"
                    width="100%"
                    height="1459"
                    src={instance?.gscUrl}
                    style={{
                      width: "100%",
                      maxWidth: "800px",
                      border: 0,
                      margin: "0 auto",
                    }}
                  ></iframe>
                </Box>
              )}

              {value === "activity" && (
                <Box className="main_nav___main">
                  <InstanceOperations instance={instance} />
                </Box>
              )}

              {value === "notes" && (
                <Box className="main_nav___main">
                  <Box className="grid_main___block">
                    <Typography className="grid_main___head" variant="body1">
                      Additional Notes
                    </Typography>
                    <Typography>
                      {instance.notes === "NA" ? (
                        <i className="false">
                          No notes has been added for this instance
                        </i>
                      ) : (
                        instance.notes
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {/* {message && (
          <Snackbar
            key={message}
            open={true}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={(e) => global?.setError(false)}
          >
            <Alert severity="success">{message}</Alert>
          </Snackbar>
        )} */}
      </Container>
      <Container maxWidth={"xl"}>
        <Typography
          variant="caption"
          component="p"
          className="grid_header"
          style={{ padding: "0 0 1rem 0", textAlign: "center" }}
        >
          Data last updated at{" "}
          {moment(localStorage.getItem("monitor_instances_cache")).format(
            "MMMM Do YYYY, h:mm a z"
          )}
        </Typography>
      </Container>
    </Box>
  );
};

export default React.memo(Main);
