import { Box, Typography } from "@mui/material";
import React from "react";
import Features from "./Features";

const InstanceAddOns = ({ instance }) => {
  return (
    <React.Fragment>
      <Box className="grid_main___block">
        <Typography className="grid_main___head" variant="body1">
          System
        </Typography>
        <Features
          title="Web Server"
          link={false}
          state={
            instance?.webserver && instance.webserver.toLowerCase() === "nginx"
              ? "Nginx"
              : "Apache"
          }
        />
        <Features
          title="Protocol"
          link="https://www.cloudflare.com/learning/performance/http2-vs-http1.1/"
          state={instance.protocol}
        />
        <Features
          title={"Security Headers"}
          link="https://htaccessbook.com/important-security-headers/"
          state={instance.headers}
        />
        {instance?.webserver &&
          instance.webserver.toLowerCase() !== "nginx" && (
            <Features
              title="Apache Mod Expires & External Filter"
              link="https://httpd.apache.org/docs/current/mod/"
              state={instance.headers}
            />
          )}
        <Features
          title="Fail2Ban"
          link="https://www.fail2ban.org/"
          state={instance.fail2ban}
        />
      </Box>

      <Box className="grid_main___block">
        <Typography className="grid_main___head" variant="body1">
          WordPress
        </Typography>
        <Features
          title="Wordfence"
          link="https://www.wordfence.com/"
          state={instance.wordfence}
        />
        <Features
          title="W3 Total Cache"
          link="https://www.boldgrid.com/w3-total-cache/"
          state={instance.w3cache}
        />
        {instance.imagify && (
          <Features
            title="Imagify"
            link="https://imagify.io/"
            state={instance.imagify}
          />
        )}
        {instance.imagify === undefined && instance.w3cache && (
          <Features
            title="W3TC WebP"
            link="https://www.w3-edge.com/weblog/2021/11/14280/"
            state={"true"}
          />
        )}
      </Box>
      <Box className="grid_main___block">
        <Typography className="grid_main___head" variant="body1">
          Amazon Web Services
        </Typography>
        <Features
          title="CDN"
          link="https://lightsail.aws.amazon.com/ls/docs/en_us/articles/amazon-lightsail-content-delivery-network-distributions"
          state={instance.cdn}
        />
        <Features
          title="Alarms"
          link="https://lightsail.aws.amazon.com/ls/docs/en_us/articles/amazon-lightsail-viewing-instance-health-metrics"
          state={instance.alarms}
        />
        <Features
          title="Snapshots"
          link="https://lightsail.aws.amazon.com/ls/docs/en_us/articles/amazon-lightsail-viewing-instance-health-metrics"
          state={
            "addOns" in instance &&
            instance.addOns &&
            instance?.addOns[0]?.name === "AutoSnapshot" &&
            instance?.addOns[0]?.status === "Enabled"
              ? "@" + instance?.addOns[0]?.snapshotTimeOfDay
              : null
          }
        />
      </Box>
    </React.Fragment>
  );
};

export default InstanceAddOns;
