import { MoreHoriz, PowerSettingsNewOutlined } from "@mui/icons-material";
import { Avatar, Box, Tooltip } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";

export const getServer = (needle, haystack) => {
  var results = haystack.filter(function (item) {
    return item.id === needle;
  });
  return results[0];
};

export const getVPS = (needle, haystack) => {
  var results = haystack.filter(function (item) {
    return item.publicIpAddress === needle;
  });
  return results[0];
};

/* Pull tags out from array to parent object */
export const getTags = (data) => {
  const revised = [];

  data.map((val, k) => {
    val.tags.map((o) => {
      val[o.key] = o.value;
      return true;
    });
    revised[k] = val;
    return true;
  });

  return revised;
};

export const handleCopy = async (text) => {
  await navigator.clipboard.writeText(text);
};

export const convertBundle = (data) => {
  let label = "NA";

  if (data === "small_2_0") {
    label = "Silver";
  } else if (data === "medium_2_0") {
    label = "Gold";
  } else if (data === "large_2_0") {
    label = "Platinum";
  }
  return label;
};

export const getState = (state, size, prefix = "") => {
  const boxStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
    alignItems: "center",
  };
  const avatarStyle = { height: "0.751rem", width: "0.75rem" };

  if (size) {
    switch (state) {
      case "deployed":
      case "running":
        return (
          <Box style={boxStyle}>
            <Avatar
              size={size}
              style={{ backgroundColor: green[500], ...avatarStyle }}
            >
              {" "}
            </Avatar>{" "}
            {(prefix && prefix + " :: ") + "Online"}
          </Box>
        );
      case "failed":
      case "stopped":
        return (
          <Box style={boxStyle}>
            <Avatar
              size={size}
              style={{ backgroundColor: red[500], ...avatarStyle }}
            >
              {" "}
            </Avatar>
            {(prefix && prefix + " :: ") + " Offline"}
          </Box>
        );
      case "deploying":
      case "stopping":
      case "pending":
        return (
          <Box style={boxStyle}>
            <Avatar
              size={size}
              style={{ backgroundColor: orange[500], ...avatarStyle }}
            >
              {" "}
            </Avatar>
            {(prefix && prefix + " :: ") + ucFirst(state)}
          </Box>
        );
      default:
        break;
    }
  } else {
    switch (state) {
      case "running":
        return (
          <Avatar
            className="grid_head___badge"
            style={{ backgroundColor: green[500] }}
          >
            <PowerSettingsNewOutlined />
          </Avatar>
        );

      case "stopped":
        return (
          <Avatar
            className="grid_head___badge"
            style={{ backgroundColor: red[500] }}
          >
            <PowerSettingsNewOutlined />
          </Avatar>
        );

      case "stopping":
      case "pending":
        return (
          <Avatar
            className="grid_head___badge"
            style={{ backgroundColor: orange[500] }}
          >
            <MoreHoriz />
          </Avatar>
        );

      default:
        break;
    }
  }
};

export const ucFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const compare = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const sortInstances = (data, filter) => {
  data = getTags(data);
  data.sort(compare);
  return data;
};

export default sortInstances;

export const defineOperationType = (term) => {
  let title = "";
  let descr = "";
  switch (term) {
    case "PutInstancePublicPorts":
      title = "Set firewall rules";
      descr =
        "Opens ports/protocols and specifies the IP addresses allowed to connect to a Site Schema instance. This action also closes all currently open ports that are not included in the request.";
      break;

    case "TagResource":
      title = " Add config info";
      descr = "Adds configuration information to the Site Schema instance";
      break;

    case "UntagResource":
      title = "Remove config info";
      descr =
        "Removes deprecated configuration information from the Site Schema instance";
      break;

    case "PutAlarm":
      title = "Add instance alarm";
      descr =
        "Creates or updates an alarm, and associates it with the specified metric on the Site Schema instance.";
      break;

    case "StartInstance":
      title = "Start instance";
      descr = "Starts the Site Schema instance from a stopped state.";
      break;

    case "StopInstance":
      title = "Stop instance";
      descr = "Stops the currently running Site Schema instance.";
      break;

    case "RebootInstance":
      title = "Reboot instance";
      descr = "Reboots the Site Schema instance.";
      break;

    case "CreateInstanceSnapshot":
      title = "Manual snapshot";
      descr =
        "Creates a snapshot of the Site Schema instance. The snapshot can be used to restore to a previous configuration.";
      break;

    case "EnableAddOn":
      title = "Add auto-snapshots";
      descr =
        "Enables (or modifies) automatic, daily snapshots on the specific Site Schema instance.";
      break;

    case "AttachStaticIp":
      title = "Add static ip";
      descr =
        "Attaches a static IP that replaces the Site Schema instance's dynamic IP address (or previously assigned static ip).";
      break;

    case "DetachStaticIp":
      title = "Detach static ip";
      descr =
        "Detaches a static IP from the Site Schema instance, and assigns a new dynamic IP address.";
      break;

    case "SetIpAddressType":
      title = "IPv6 networking";
      descr =
        "Enables IPv6 networking on the Site Schema instance allowing communication using both IPv4 and IPv6 protocols";
      break;

    case "CreateInstance":
      title = "Create instance";
      descr = "Initial creation (and build out) of the Site Schema instance.";
      break;

    default:
      title = term;
      descr = term;
  }

  return (
    <Tooltip title={descr} arrow placement="right">
      <Box component="span">{title}</Box>
    </Tooltip>
  );
};
