import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Message from "./Message";
import UptimeRobot from "../UptimeRobot";

const Breadcrumb = ({ focused, instance, closeView }) => {
  return (
    <Container id="breadcrumbs" maxWidth={"xl"}>
      <Breadcrumbs separator="/" aria-label="breadcrumbs">
        <Link
          style={{ cursor: "pointer" }}
          underline="hover"
          key="1"
          color="inherit"
          onClick={(e) => closeView()}
        >
          Instances
        </Link>
        {focused && instance && (
          <Typography key="2" color="inherit">
            <b>{instance?.company}</b>
          </Typography>
        )}
      </Breadcrumbs>
      <UptimeRobot />
      <Message />
    </Container>
  );
};

export default Breadcrumb;
