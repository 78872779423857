import React, { useContext } from "react";
import moment from "moment";

import { Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { Box } from "@mui/system";

/* Site Schema Components */
import { AppContext } from "../../includes/AppContext";

const InstanceDetails = ({ instance }) => {
  const global = useContext(AppContext);

  const convertBundle = (data) => {
    let label = "NA";

    if (data === "small_2_0") {
      label = "Silver";
    } else if (data === "medium_2_0") {
      label = "Gold";
    }
    return label;
  };

  return (
    <Box className="grid_main___block">
      <Typography className="grid_main___head" variant="body1">
        Overview
      </Typography>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          Name
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {instance.name}
        </Typography>
      </Box>
      {instance.client !==
        global?.user?.attributes["custom:company"].toLowerCase() && (
        <Box className="grid_row">
          <Typography variant="body2" className="grid_main___key">
            Client:
          </Typography>
          <Typography variant="body2" className="grid_main___val">
            <span style={{ textTransform: "uppercase" }}>
              {instance.client}
            </span>
          </Typography>
        </Box>
      )}
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          Plan
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {convertBundle(instance.bundleId)}
        </Typography>
      </Box>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          Region
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {instance.location.regionName}
        </Typography>
      </Box>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          <Tooltip
            title="Reflects age since last rebuild"
            placement="right"
            arrow
          >
            <Box
              component="span"
              style={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              Age
              <Info style={{ height: "1rem", width: "1rem", color: "#888" }} />
            </Box>
          </Tooltip>
        </Typography>
        <Typography variant="body2" className="grid_main___val">
          {moment(instance.createdAt).fromNow(true)}
        </Typography>
      </Box>
    </Box>
  );
};

export default InstanceDetails;
