import React, { useContext } from "react";

import { IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../../includes/AppContext";
import { handleCopy } from "../../includes/utilities";
import { CopyAll } from "@mui/icons-material";
import Features from "./Features";

const InstanceIpAddresses = ({ instance }) => {
  const global = useContext(AppContext);

  const copyIpAddress = (val) => {
    global?.setMessage(
      "Successfully copied the IP Address " + val + " to your clipboard"
    );
    handleCopy(val);
  };

  return (
    <Box className="grid_main___block">
      <Typography className="grid_main___head" variant="body1">
        Networking
      </Typography>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          {instance.publicIpAddress}
        </Typography>
        <Tooltip
          title="Copy IP Address"
          placement="left"
          arrow
          style={{ zIndex: "1" }}
        >
          <IconButton
            className="copy_button"
            size="small"
            style={{
              cursor: "pointer",
              borderRadius: 0,
              backgroundColor: "#2277dd",
              color: "white",
              display: global?.navigation ? "none" : "inline-flex",
            }}
            onClick={(e) => {
              copyIpAddress(instance.publicIpAddress);
              return true;
            }}
          >
            <CopyAll style={{ height: 16, width: 16 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className="grid_row">
        <Typography variant="body2" className="grid_main___key">
          {instance?.ipv6Addresses[0]}
        </Typography>
        <Tooltip
          title="Copy IP v6 Address"
          placement="left"
          arrow
          style={{ zIndex: 500 }}
        >
          <IconButton
            className="copy_button"
            size="small"
            style={{
              cursor: "pointer",
              borderRadius: 0,
              backgroundColor: "#2277dd",
              color: "white",
              display: global?.navigation ? "none" : "inline-flex",
            }}
            onClick={(e) => {
              copyIpAddress(instance.ipv6Addresses[0]);
              return true;
            }}
          >
            <CopyAll style={{ height: 16, width: 16 }} />
          </IconButton>
        </Tooltip>
      </Box>
      {instance?.webserver &&
        instance.webserver.toLowerCase() === "nginx" &&
        instance?.ufw === "true" && (
          <Features
            title="UFW (Nginx only)"
            link="https://wiki.ubuntu.com/UncomplicatedFirewall"
            state={instance.ufw}
          />
        )}
    </Box>
  );
};

export default InstanceIpAddresses;
