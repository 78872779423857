import { Alert, Collapse } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../includes/AppContext";

const Message = () => {
  const global = useContext(AppContext);

  return (
    <React.Fragment>
      {global?.message && (
        <Collapse in={global?.message.length > 0 ? true : false}>
          <Alert
            onClose={() => global?.setMessage(null)}
            style={{ marginTop: "1rem" }}
          >
            {global?.message}
          </Alert>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default Message;
