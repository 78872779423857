import React, { useEffect } from "react";
import ReactGa4 from "react-ga4";

/* Material UI Components */
import { Box, Button, Typography } from "@mui/material";

/* Amplify-UI Components */
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../aws-exports";

/* Site Schema Components */
import Logo from "../images/site-schema-black.png";
import App from "./App";

Amplify.configure(awsExports);

const Login = ({ signOut, user }) => {
  useEffect(() => {
    ReactGa4.initialize("G-19SZY3QVYP");
    ReactGa4.send({
      hitType: "pageview",
    });
  }, []);

  /* Amplify-UI
   * https://ui.docs.amplify.aws/react/components/authenticator#sign-up-fields
   */
  const formFields = {
    signUp: [
      {
        label: "Company Name",
        type: "custom:company",
        required: true,
        placeholder: "Company Name (provided by Site Schema)",
        labelHidden: true,
      },
      {
        type: "name",
        label: "Contact Name",
        placeholder: "Full Name",
        required: true,
        labelHidden: true,
      },
    ],
  };

  const components = {
    Header() {
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            margin: "3rem auto 2rem",
          }}
        >
          <img src={Logo} alt="Site Schema" />
        </Box>
      );
    },
    Footer() {
      return (
        <Box style={{ textAlign: "center", padding: "1rem" }}>
          <Typography variant="body2">
            &copy; 2022 <b>Site Schema, LLC</b>. Proudly made in{" "}
            <b>Rhode Island</b>
          </Typography>
        </Box>
      );
    },

    SignIn: {
      Header() {
        return (
          <Typography
            variant="h2"
            style={{ fontSize: "1.6rem", textAlign: "center", padding: "1rem" }}
          >
            Sign in to your account
          </Typography>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <Box style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Button variant="text" onClick={toResetPassword} size="small">
              Reset Password
            </Button>
          </Box>
        );
      },
    },

    SignUp: {
      Header() {
        return (
          <Typography
            variant="h2"
            style={{ fontSize: "1.6rem", textAlign: "center", padding: "1rem" }}
          >
            Create a new account
          </Typography>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <Box style={{ textAlign: "center", marginBottom: "1rem" }}>
            <Button variant="text" onClick={toSignIn} size="small">
              Back to Sign In
            </Button>
          </Box>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        return (
          <Typography
            variant="h2"
            style={{
              fontSize: "1.6rem",
              textAlign: "center",
              padding: "1rem 1rem 0",
            }}
          >
            We Emailed You
          </Typography>
        );
      },
    },
    ResetPassword: {
      Header() {
        return (
          <Typography
            variant="h2"
            style={{ fontSize: "1.6rem", textAlign: "center", padding: "1rem" }}
          >
            Reset Your Password
          </Typography>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <Typography
            variant="h2"
            style={{ fontSize: "1.6rem", textAlign: "center", padding: "1rem" }}
          >
            Reset Your Password
          </Typography>
        );
      },
    },
  };

  return (
    <Authenticator
      components={components}
      formFields={formFields}
      signUpAttributes={[]}
    >
      {({ signOut, user }) => (
        <>
          {user.attributes["custom:company"] && (
            <App user={user} signOut={signOut} />
          )}
        </>
      )}
    </Authenticator>
  );
};

export default Login;
