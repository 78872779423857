import moment from "moment";

const cacheCheck = ({ cacheName, cacheLength, cacheUnit }) => {
  let cache = false;
  const prefix = "_cache";

  if (
    localStorage.getItem(cacheName) &&
    localStorage.getItem(cacheName + prefix)
  ) {
    const cacheDate = localStorage.getItem(cacheName + prefix);

    if (moment(cacheDate).isAfter(moment().subtract(cacheLength, cacheUnit))) {
      console.log(
        " - " + cacheName + " cache IN range: " + cacheLength + " " + cacheUnit
      );
      cache = true;
    } else {
      console.log(
        " - " +
          cacheName +
          " cache NOT IN range: " +
          cacheLength +
          " " +
          cacheUnit
      );
      cache = false;
    }
  } else {
    console.log(" - " + cacheName + " cache NOT found ");
    cache = false;
  }

  return cache;
};

export default cacheCheck;
