import {
  Fence,
  Language,
  LocalHospital,
  MultipleStop,
  Security,
  Warning,
  Lock,
  Camera,
  Work,
  Category,
  Check,
  Storage,
  Image,
} from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import { blue, green } from "@mui/material/colors";

const Features = ({ link = false, title, state }) => {
  const getIcon = (title) => {
    const params = {
      className: "grid_main___icon left",
      style: { color: blue[500] },
    };
    switch (title.toLowerCase()) {
      case "fail2ban":
        return <Security {...params} />;

      case "wp db repair":
        return <LocalHospital {...params} />;

      case "cdn":
        return <Language {...params} />;

      case "w3 total cache":
        return <Work {...params} />;

      case "wordfence":
        return <Fence {...params} />;

      case "protocol":
        return <MultipleStop {...params} />;

      case "imagify":
      case "w3tc webp":
        return <Image {...params} />;

      case "web server":
        return <Storage {...params} />;

      case "alarms":
        return <Warning {...params} />;

      case "security headers":
        return <Lock {...params} />;

      case "snapshots":
        return <Camera {...params} />;

      case "apache mod expires & external filter":
        return <Category {...params} />;

      default:
        return;
    }
  };

  const getState = (state) => {
    switch (state) {
      case "true":
        return (
          <Avatar
            style={{
              backgroundColor: green[600],
              height: "1rem",
              width: "1rem",
            }}
          >
            <Check style={{ color: "white", fontSize: "0.875rem" }} />
          </Avatar>
        );
      case "premium":
        return "Premium";
      case "standard":
        return "Basic";
      case "HTTP/1.1":
        return state;
      case "h2":
        return "HTTP2";
      case "":
      case "false":
      case null:
      case undefined:
        return <i className="false">&mdash;</i>;
      default:
        return state;
    }
  };

  const getLink = (link, title) => {
    // console.log("LINK:", link);
    return link ? (
      <a href={link} target="_new">
        {title}
      </a>
    ) : (
      <>{title}</>
    );
  };

  return (
    <Box className="grid_row">
      <Typography variant="body2" className="grid_main___key">
        {getIcon(title)}
        {getLink(link, title)}
      </Typography>
      <Typography variant="body2" className="grid_main___val">
        {getState(state)}
      </Typography>
    </Box>
  );
};

export default Features;
