import { useContext, useEffect, useRef } from "react";

import { MoreHoriz } from "@mui/icons-material";
import {
  IconButton,
  Tooltip,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Divider,
  Tab,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import { getState, ucFirst } from "../../includes/utilities";
import { AppContext } from "../../includes/AppContext";

const InstanceHead = ({ instance, setStatus, value, handleChange }) => {
  const global = useContext(AppContext);
  const anchorRef = useRef(null);

  const handleCommands = (action) => {
    setStatus(action);
    global?.setNavigation(false);

    localStorage.removeItem("monitor_instances");
    localStorage.removeItem("monitor_instances_cache");

    setTimeout(() => {
      localStorage.removeItem("monitor_instances");
      localStorage.removeItem("monitor_instances_cache");
      global?.setConfig((prev) => ({
        ...prev,
        timestamp: new Date().getTime(),
      }));
    }, 4000);
  };

  const handleToggle = () => {
    global?.setNavigation((prevOpen) => !prevOpen);
    // setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    global?.setNavigation(false);
    // setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(global?.navigation);
  useEffect(() => {
    if (prevOpen.current === true && global?.navigation === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = global?.navigation;
  }, [global?.navigation]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      global?.setNavigation(false);
    } else if (event.key === "Escape") {
      global?.setNavigation(false);
    }
  }

  return (
    <Box
      style={{
        position: "sticky",
        top: 0,
        backgroundColor: "#f4f5f6",
        zIndex: 1,
        borderBottom: "1px solid #ddd",
      }}
    >
      <Box className="grid_head">
        <Box className="grid_head___status" style={{ width: "max-content" }}>
          <Tooltip
            title={"Instance is " + instance.state.name}
            arrow
            placement="right"
          >
            <Box component="span">
              {getState(instance?.state?.name, "large", instance?.company)}
            </Box>
          </Tooltip>
        </Box>
        <IconButton
          ref={anchorRef}
          size="large"
          id="composition-button"
          aria-controls={global?.navigation ? "composition-menu" : undefined}
          aria-expanded={global?.navigation ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ borderRadius: 0, backgroundColor: "#2277cc", color: "#fff" }}
        >
          <MoreHoriz />
        </IconButton>
        <Popper
          open={global?.navigation}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          style={{
            zIndex: 10000,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-end" ? "right top" : "right bottom",
              }}
            >
              <Paper
                style={{
                  minWidth: "12rem",
                  backgroundColor: "#2277cc",
                  color: "white",
                  borderRadius: "0",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={global?.navigatio}
                    id="basic-menu"
                    onKeyDown={handleListKeyDown}
                    aria-labelledby="composition-button"
                  >
                    <MenuItem
                      size={"small"}
                      variant="outlined"
                      onClick={(e) => window.open(instance?.url, "_blank")}
                    >
                      View Website
                    </MenuItem>
                    <MenuItem
                      size={"small"}
                      variant="outlined"
                      onClick={(e) =>
                        window.open(instance?.url + "/wp-admin/", "_blank")
                      }
                    >
                      WordPress Admin
                    </MenuItem>
                    <Divider style={{ background: "#ffffff88" }} />
                    <MenuItem
                      size={"small"}
                      variant="outlined"
                      onClick={(e) =>
                        window.open(
                          "https://www.ssllabs.com/ssltest/analyze.html?hideResults=on&latest&d=" +
                            instance.url,
                          "_blank"
                        )
                      }
                    >
                      SSL Lab Server Test
                    </MenuItem>
                    <MenuItem
                      size={"small"}
                      variant="outlined"
                      onClick={(e) =>
                        window.open(
                          "https://pagespeed.web.dev/report?url=" +
                            instance.url,
                          "_blank"
                        )
                      }
                    >
                      Pagespeed Insights
                    </MenuItem>
                    <MenuItem
                      size={"small"}
                      variant="outlined"
                      onClick={(e) =>
                        window.open(
                          "https://www.gtmetrix.com/?url=" + instance.url,
                          "_blank"
                        )
                      }
                    >
                      GTmetrix Performance
                    </MenuItem>

                    <Divider style={{ background: "#ffffff88" }} />

                    <MenuItem
                      size={"small"}
                      variant="outlined"
                      onClick={(e) => alert("Snapshot")}
                    >
                      Snapshot
                    </MenuItem>

                    <Divider style={{ background: "#ffffff88" }} />

                    {[
                      { action: "reboot", when: "running" },
                      { action: "start", when: "stopped" },
                      { action: "stop", when: "running" },
                    ].map((val, key) => {
                      return (
                        <MenuItem
                          size={"small"}
                          key={"button-" + key}
                          variant="outlined"
                          disabled={
                            val.when === instance.state.name ? false : true
                          }
                          onClick={(e) =>
                            handleCommands(val.action.toLowerCase())
                          }
                        >
                          {ucFirst(val.action)}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <Tabs
        style={{
          padding: "1rem 0 0",
          width: "100%",
        }}
        variant="scrollable"
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        value={value}
        onChange={handleChange}
      >
        <Tab
          style={{ fontSize: "0.75rem", padding: "0.25 0.5rem" }}
          label="Overview"
          value="overview"
        />
        {/* <Tab
          style={{ fontSize: "0.75rem", padding: "0.25 0.5rem" }}
          label="Config"
          value="config"
        /> */}
        <Tab
          style={{ fontSize: "0.75rem", padding: "0.25 0.5rem" }}
          label="Activity"
          value="activity"
        />

        {instance.analyticsUrl && (
          <Tab
            style={{ fontSize: "0.75rem", padding: "0.25 0.5rem" }}
            label="Analytics"
            value="analytics"
          />
        )}
        {instance.gscUrl && (
          <Tab
            style={{ fontSize: "0.75rem", padding: "0.25 0.5rem" }}
            label="Discoverability"
            value="discoverability"
          />
        )}
        {/* <Tab
          style={{ fontSize: "0.75rem", padding: "0.25 0.5rem" }}
          label="Performance"
          value="performance"
        />
				*/}
        {instance.notes && (
          <Tab
            style={{ fontSize: "0.75rem", padding: "0.25 0.5rem" }}
            label="Notes"
            value="notes"
          />
        )}
      </Tabs>
    </Box>
  );
};

export default InstanceHead;
