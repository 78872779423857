/* @ts-check */
/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WebFont from "webfontloader";
import moment from "moment";

import { AppContext } from "../includes/AppContext";
import cacheCheck from "../includes/cacheCheck";
import sortInstances from "../includes/utilities";
import Main from "./Main";

function App({ user, signOut }) {
  const [init, setInit] = useState(null);
  const [error, setError] = useState(null);
  const [width, setWidth] = useState(null);
  const [sites, setSites] = useState(null);
  const [alarms, setAlarms] = useState(null);
  const [height, setHeight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [servers, setServers] = useState(null);
  const [instances, setInstances] = useState(null);
  const [navigation, setNavigation] = useState(false);

  const [config, setConfig] = useState({
    interval: 1,
    length: 0,
    unit: "minutes",
    lsName: "monitor_instances",
    lsSites: "monitor_sites",
    lsSrvrs: "monitor_servers",
    lsCache: "monitor_instances_cache",
    lsOps: "monitor_operations",
    lsOpsCache: "ls_operations_cache",
    lsToken: "monitor_nextToken",
    url: "https://7syz1k6odi.execute-api.us-east-1.amazonaws.com/default/lightsail",
    timestamp: new Date().getTime(),
  });

  const getSites = useCallback(
    ({ token = null, prevData = null }) => {
      const url = config.url + "/sites/";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          const dataObj = JSON.parse(data.message);

          const dSites = dataObj.sites;
          setSites(dSites);
          localStorage.setItem(config.lsSites, JSON.stringify(dSites));

          const dSrvrs = dataObj.servers;
          setServers(dSrvrs);
          localStorage.setItem(config.lsSrvrs, JSON.stringify(dSrvrs));
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          setError(error);
        })
        .finally();
    },
    [config]
  );

  /* Callback to retrieve all instances from API */
  const getInstances = useCallback(
    ({ token = null, prevData = null }) => {
      const url =
        config.url +
        "/instances/" +
        "?" +
        (token && token !== "" ? "&pageToken=" + token : "") +
        ("custom:company" in user?.attributes &&
        user?.attributes["custom:company"] !== ""
          ? "&filter=" +
            encodeURI(user.attributes["custom:company"].toLowerCase())
          : "");
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          const dataObj = JSON.parse(data.message);
          let final = [];

          /* If pulling additional instances, append to array */
          if (token && token !== "") {
            final = [...prevData, ...dataObj.instances];
          } else {
            final = dataObj.instances;
            setInstances(final);
          }

          /* If more results, loop through getInstances again */
          if (dataObj.nextPageToken) {
            getInstances({
              token: dataObj.nextPageToken,
              prevData: dataObj.instances,
            });
            localStorage.setItem(config.lsToken, dataObj.nextPageToken);

            /* If no more instances then */
          } else {
            const sorted = sortInstances(final, "name");
            // console.log("Instances: ", sorted);
            localStorage.setItem(config.lsName, JSON.stringify(sorted));
            localStorage.setItem(config.lsCache, moment().format());
            setLoading(false);
            setInstances(sorted);
          }
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
          setError(error);
        })
        .finally();
    },
    [config]
  );

  /* Fix for safari mobile */
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
      // console.log(" - Size: " + window.innerHeight + "x" + window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [loading]);

  /* Load primary font family */
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Droid Sans", "Chilanka"],
      },
    });
  }, []);

  /* Get all instances from API */
  useEffect(() => {
    const cache = cacheCheck({
      cacheName: "monitor_instances",
      cacheLength: config.length,
      cacheUnit: config.unit,
    });

    if (cache) {
      const respSites = localStorage.getItem(config.lsSites);
      setSites(JSON.parse(respSites));

      const respSrvrs = localStorage.getItem(config.lsSrvrs);
      setServers(JSON.parse(respSrvrs));

      const respInstances = localStorage.getItem(config.lsName);
      setInstances(JSON.parse(respInstances));

      setLoading(false);
    } else {
      getSites({});
      getInstances({});
    }
  }, [config, init]);

  /* Start check every 1 minute */
  useEffect(() => {
    // console.log("Set Interval");
    const check = () => {
      if (document.hasFocus()) {
        setInit(new Date());
      }
    };
    if (init === null) {
      setInterval(check, 1000 * 60 * config.interval);
    }
  }, [config]); // Only run once

  return (
    <AppContext.Provider
      value={{
        user,
        init,
        error,
        config,
        sites,
        servers,
        instances,
        message,
        alarms,
        loading,
        width,
        height,
        navigation,
        setInit,
        setError,
        setAlarms,
        setConfig,
        setMessage,
        setNavigation,
        signOut,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="view/:instanceid" element={<Main />} />
            <Route index element={<Main />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
