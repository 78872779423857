import React, { useContext, useEffect, useState } from "react";
import { Alert, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../includes/AppContext";
import cacheCheck from "../includes/cacheCheck";
import moment from "moment";

const UptimeRobot = () => {
  const global = useContext(AppContext);
  const goto = useNavigate();

  const [alerts, setAlerts] = useState(null);
  const [config] = useState({
    length: 1,
    unit: "minutes",
    lsName: "monitor_uptime",
    lsCache: "monitor_uptime_cache",
    url: "https://7syz1k6odi.execute-api.us-east-1.amazonaws.com/default/lightsail/uptime",
  });

  useEffect(() => {
    const cache = cacheCheck({
      cacheName: "monitor_uptime",
      cacheLength: config.length,
      cacheUnit: config.unit,
    });

    if (cache) {
      const response = localStorage.getItem("monitor_uptime");
      // console.log("Uptimes: ", JSON.parse(response));
      setAlerts(JSON.parse(response));
    } else {
      fetch(config.url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          const response = JSON.parse(data.message);
          // console.log(response.monitors);

          setAlerts(response.monitors);
          localStorage.setItem(
            "monitor_uptime",
            JSON.stringify(response.monitors)
          );
          localStorage.setItem("monitor_uptime_cache", moment().format());
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        })
        .finally();
    }
  }, [config, global?.init]);

  return (
    <div>
      {alerts &&
        alerts.map((val, key) => {
          if (val?.status > 2) {
            return (
              <Collapse in={global?.setMessage.length > 0 ? true : false}>
                <Alert
                  key={key}
                  severity="error"
                  onClick={(e) => {
                    key in global?.instances
                      ? goto("/view/" + val?.friendly_name)
                      : alert("Monitor is DOWN: " + val?.friendly_name);
                  }}
                >
                  Monitor is DOWN: {val?.friendly_name}
                </Alert>
              </Collapse>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

export default UptimeRobot;
